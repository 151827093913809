import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';

// import Hero from '../components/Hero';
import Blurbs from '../components/Blurbs';
import ContentSection from '../components/ContentSection';
import FinalSection from '../components/FinalSection';
// import BlogSection from '../components/BlogSection';
import CTA from '../components/CTA';
import SEO from '../components/SEO/SEO';

import { Hero, Section } from '../components/bulma';

export default function PlatformPage({ data }) {
	const { markdownRemark: page } = data;
	const { image, title, description, heading, subheading, sections, blurbs, final, slug } = page.frontmatter;

	return (
		<Layout>
			<SEO
				title={title}
				description={description}
				// image={image.childImageSharp.sizes.src}
				pathname={slug}
				article
			/>
			<PlatformPageTemplate
				image={image}
				heading={heading}
				subheading={subheading}
				blurbs={blurbs}
				sections={sections}
				final={final}
			/>
		</Layout>
	);
}

export const PlatformPageTemplate = ({ image, heading, subheading, sections, blurbs, final }) => (
	<div>
		<Hero size="medium">
			<div className="columns is-mobile is-centered">
				<div className="column is-half-desktop has-text-centered">
					<h1
						className="title is-hidden-mobile has-text-centered has-text-weight-bold is-spaced"
						style={{ fontSize: '56px', fontWeight: 'bolder' }}
					>
						{heading}
					</h1>
					<h1 className="title is-hidden-desktop is-size-2-mobile has-text-centered has-text-weight-bold is-spaced">
						<br />
						{heading}
					</h1>
					<h2 className="subtitle has-text-centered is-size-4-mobile is-size-4">{subheading}</h2>

					<div className="buttons  is-centered">
						<p className="control">
							<a href={`https://app.payment.ninja/signup`} className="button is-primary is-large">
								<strong>START FREE</strong>
							</a>
						</p>
						<p className="control">
							<Link to="/savings-calculator" className="button is-text is-large">
								Estimate savings
							</Link>
						</p>
						{/* <p className="control">
							<a
								href="https://calendly.com/finhub/payment-ninja-demo"
								className="button is-text is-large"
							>
								Free consultation
							</a>
						</p> */}
					</div>
				</div>
			</div>
		</Hero>
		<Blurbs box items={blurbs} />
		<ContentSection items={sections} box />
		{/* <FinalSection content={final} /> */}
		{/* <BlogSection /> */}
		<CTA />
	</div>
);

export const pageQuery = graphql`
	query PlatformPageByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			fields {
				slug
			}
			frontmatter {
				title
				description
				heading
				subheading
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
				blurbs {
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
					title
					text
				}
				sections {
					title
					text
					color
					side
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				final {
					title
					text
					Image {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 400, quality: 64) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		}
	}
`;
